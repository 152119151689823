import {uniqueId} from '@/utilities/component.js';
import {wrapInner} from '@/utilities/manipulation.js';
import {supportsSessionStorage} from '@/utilities/environment.js';

const SELECTOR_HEADER = 'h1, h2, h3, h4, h5, h6, [data-header]';
const SELECTOR_PANEL = 'h1 ~ *, h2 ~ *, h3 ~ *, h4 ~ *, h5 ~ *, h6 ~ *, [data-header] ~ *';

const template = document.createElement('template');

template.innerHTML = /*html*/`
  <style>
    :host {
      display: block;
    }

    ::slotted(:where(h1, h2, h3, h4, h5, h6, [data-header])) {
      display: block;
      width: 100%;
    }
  </style>
  <slot></slot>
`;

const marker = document.createElement('template');

marker.innerHTML = /*svg*/`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true">
    <path d="M 3,8 l 8,8 l 8,-8" fill="none" stroke="currentcolor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
`;

/**
 * @todo handle slot changes
 * @todo improve label of header button
 */
export class Accordion extends HTMLElement {
  #supportsSessionStorage = false;

  #headers = [];
  #panels = [];

  constructor() {
    super();

    const shadowRoot = this.attachShadow({mode: 'open'});
    shadowRoot.appendChild(template.content.cloneNode(true));
  }

  connectedCallback() {
    this.#supportsSessionStorage = supportsSessionStorage();

    this.#headers = Array.from(this.children).filter((element) => (
      element.matches(SELECTOR_HEADER)
    ));

    this.#panels = Array.from(this.children).filter((element) => (
      !element.matches(SELECTOR_HEADER) && element.matches(SELECTOR_PANEL)
    ));

    if (this.#headers.length !== this.#panels.length) {
      throw new Error('[Accordion] Unexpected accordion content');
    }

    this.#upgradeHeaders(this.#headers, this.#panels);

    if (this.#shouldRememberState()) {
      this.#restoreState();
    }

    this.addEventListener('click', this.#handleClick);
    this.addEventListener('keydown', this.#handleKeydown);
    this.addEventListener('beforematch', this.#handleBeforeMatch);
  }

  disconnectedCallback() {
    this.removeEventListener('beforematch', this.#handleBeforeMatch);
    this.removeEventListener('keydown', this.#handleKeydown);
    this.removeEventListener('click', this.#handleClick);
  }

  get remember() {
    return this.hasAttribute('remember');
  }

  set remember(value) {
    if (value) {
      this.setAttribute('remember', '');
    } else {
      this.removeAttribute('remember');
    }
  }

  #getItem(array, index) {
    return array[(index % array.length + array.length) % array.length];
  }

  #getHeader(index) {
    return this.#getItem(this.#headers, index);
  }

  #getPanel(index) {
    return this.#getItem(this.#panels, index);
  }

  #findHeader(element) {
    return this.#headers.findIndex((header) => header === element);
  }

  #findPanel(element) {
    return this.#panels.findIndex((panel) => panel === element);
  }

  #shouldRememberState() {
    return this.id && this.remember && this.#supportsSessionStorage;
  }

  #storeState() {
    this.#headers.forEach((_header, index) => {
      this.#storeItemState(index, this.#getItemState(index));
    });
  }

  #restoreState() {
    this.#headers.forEach((_header, index) => {
      this.#restoreItemState(index);
    });
  }

  #getItemState(index) {
    const button = this.#getHeader(index)?.querySelector('button');

    if (!button) {
      return false;
    }

    return button.getAttribute('aria-expanded') === 'true';
  }

  #getItemKey(index) {
    const button = this.#getHeader(index)?.querySelector('button');

    if (!button) {
      return null;
    }

    const key = button.getAttribute('aria-controls');

    if (!key) {
      return key;
    }

    return `${this.id}-${key}`;
  }

  #toggleItemState(index, force) {
    this.#setItemState(index, force ?? !this.#getItemState(index));
  }

  #setItemState(index, expanded = true) {
    const header = this.#getHeader(index);
    const panel = this.#getPanel(index);

    if (expanded) {
      header?.querySelector('button')?.setAttribute('aria-expanded', 'true');
      panel?.setAttribute('data-expanded', '');
      panel?.removeAttribute('hidden');
    } else {
      header?.querySelector('button')?.setAttribute('aria-expanded', 'false');
      panel?.setAttribute('hidden', 'until-found');
      panel?.removeAttribute('data-expanded');
    }

    if (this.#shouldRememberState()) {
      this.#storeItemState(index, expanded);
    }
  }

  #storeItemState(index, expanded = true) {
    const key = this.#getItemKey(index);

    if (!key) {
      return;
    }

    window.sessionStorage.setItem(key, expanded ? 'true' : 'false');
  }

  #restoreItemState(index) {
    const key = this.#getItemKey(index);

    if (!key) {
      return false;
    }

    const expanded = window.sessionStorage.getItem(key) === 'true';
    this.#setItemState(index, expanded);

    return expanded;
  }

  #upgradeHeaders(headers, panels) {
    headers.forEach((header, index) => {
      const panel = panels[index];

      panel.id ||= uniqueId('accordion-panel');
      panel.hidden = 'until-found';

      const button = document.createElement('button');

      button.id ||= uniqueId('accordion-header');

      panel.setAttribute('aria-labelledby', button.id);
      button.setAttribute('aria-controls', panel.id);
      button.setAttribute('type', 'button');
      button.setAttribute('aria-expanded', 'false');

      wrapInner(header, button);
      button.appendChild(marker.content.cloneNode(true));
    });
  }

  /** @param {MouseEvent} event */
  #handleClick = (event) => {
    const index = this.#findHeader(event.target.closest(SELECTOR_HEADER));

    if (index === -1) {
      return;
    }

    this.#toggleItemState(index);
    event.preventDefault();
  };

  /** @param {KeyboardEvent} event */
  #handleKeydown = (event) => {
    const index = this.#findHeader(event.target.closest(SELECTOR_HEADER));

    if (index === -1) {
      return;
    }

    const moveFocusTo = (index) => {
      this.#getHeader(index)
        ?.querySelector('button')
        ?.focus();
    };

    if (event.key === 'ArrowUp') {
      moveFocusTo(index - 1);
      event.preventDefault();
    } else if (event.key === 'ArrowDown') {
      moveFocusTo(index + 1);
      event.preventDefault();
    } else if (event.key === 'Home') {
      moveFocusTo(0);
      event.preventDefault();
    } else if (event.key === 'End') {
      moveFocusTo(this.#headers.length - 1);
      event.preventDefault();
    }
  };

  /** @param {Event} event */
  #handleBeforeMatch = (event) => {
    const index = this.#findPanel(event.target.closest(SELECTOR_PANEL));

    if (index === -1) {
      return;
    }

    this.#setItemState(index, true);
  };
}
