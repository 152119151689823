export default class App {
  #header = null;
  #observer = null;

  constructor() {
    this.#header = document.querySelector('.site-header');
  }

  bootstrap() {
    this.#observer = new IntersectionObserver(([entry]) => {
      entry.target.classList.toggle('is-stuck', entry.intersectionRatio < 1);
    }, {
      threshold: [1],
    });

    this.#observer.observe(this.#header);
  }
}
